import * as React from 'react'
import styled, { injectGlobal } from 'react-emotion'

injectGlobal({
  '*': {
    boxSizing: 'border-box'
  },
  body: {
    padding: 0,
    margin: 0
  }
})

const Container = styled('div')`
  width: 100%;
  height: 100vh;
  background-color: dodgerblue;
  color: white;
  border: 7px solid white;
  font-family: 'Helvetica', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled('div')`
  text-align: center;
`

const Heading = styled('h1')`
  color: white;
`

const Subheading = styled('h3')`
  color: white;
  opacity: 0.7;
`

const IndexPage = () => (
  <Container>
    <Content>
      <Heading>Kayce West</Heading>
      <Subheading>Video Production Specialist</Subheading>
    </Content>
  </Container>
)

export default IndexPage
